import { dateHelpers } from "@runn/calculations"
import { startOfToday } from "date-fns"

export const formatName = (firstName: string, lastName: string): string =>
  `${firstName} ${lastName}`

export const formatNameAsInitials = (firstName: string, lastName: string) => {
  const first = (firstName?.toUpperCase() ?? "").slice(0, 1)
  const last = (lastName?.toUpperCase() ?? "").slice(0, 1)
  return first + last
}

type ContractWithStartAndEndDate = {
  start_date: string
  end_date: string
}

export const getCurrentContract = <T extends ContractWithStartAndEndDate>(
  contracts: readonly T[],
): T => {
  const currentDate = startOfToday()
  const contract = contracts
    .filter(
      (c) =>
        !c.end_date || dateHelpers.parseRunnDate(c.end_date) >= currentDate,
    )
    .sort((a, b) => Number(a.start_date) - Number(b.start_date))[0]
  return contract
}

export const getCurrentOrLastContract = <T extends ContractWithStartAndEndDate>(
  contracts: readonly T[],
): T => {
  const currentContract = getCurrentContract(contracts)

  if (currentContract) {
    return currentContract
  }

  // Get the last contract
  const currentDate = startOfToday()

  const contract = contracts
    .filter(
      (c) =>
        !c.end_date || dateHelpers.parseRunnDate(c.end_date) <= currentDate,
    )
    .sort((a, b) => Number(b.start_date) - Number(a.start_date))[0]

  return contract
}

export const getContractsBetweenDates = <T extends ContractWithStartAndEndDate>(
  contracts: readonly T[],
  startDate: string,
  endDate: string,
) => {
  const validContracts = contracts.filter(
    (c) =>
      !(
        // Then exlcude them
        // Find any that end before this project starts
        (
          (c.end_date && Number(c.end_date) < Number(startDate)) ||
          // Find any that start after they end
          Number(c.start_date) > Number(endDate)
        )
      ),
  )
  return validContracts
}

export const isSamePerson = (emailA: string, emailB: string) => {
  if (!emailA || !emailB) {
    return false
  }
  return emailA?.trim().toLowerCase() === emailB?.trim().toLowerCase()
}

export const getSecondaryPersonField = ({
  contracts,
  secondaryPersonField,
}: {
  contracts: readonly (ContractWithStartAndEndDate & {
    role?: { name: string | undefined }
    job_title: string | undefined
  })[]
  secondaryPersonField: string
}): string => {
  const contract = getCurrentOrLastContract(contracts)

  if (!contract) {
    return "No Contract"
  }

  const roleName = contract.role?.name ?? ""

  if (secondaryPersonField === "job_title") {
    return contract.job_title ?? ""
  } else {
    return roleName
  }
}

export default {
  formatName,
  formatNameAsInitials,
  getCurrentContract,
  isSamePerson,
}
