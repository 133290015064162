import React from "react"
import { graphql, useFragment } from "react-relay"

import styles from "./StatusTooltipContent.module.css"

import { StatusTooltipContent_gql$key } from "./__generated__/StatusTooltipContent_gql.graphql"

import Button from "~/common/buttons/Button"

import { StatusProps } from "./Status"
import { Status } from "./get-status"

type TooltipContentProps = {
  status: Status
  currentUser: StatusProps["currentUser"]
  onOpenIconTable: () => void
  onOpenFeatureFlags: () => void
  onOpenNotifications: () => void
  gql: StatusTooltipContent_gql$key
}

const StatusTooltipContent = (props: TooltipContentProps) => {
  const {
    status,
    onOpenIconTable,
    onOpenFeatureFlags,
    onOpenNotifications,
    currentUser,
  } = props

  const data = useFragment(
    graphql`
      fragment StatusTooltipContent_gql on query_root {
        billing(syncSubscription: false) {
          subscription {
            id
            plan {
              id
              itemPriceId
            }
          }
        }
      }
    `,
    props.gql,
  )

  const subscriptionPlan = data.billing.subscription?.plan?.itemPriceId || null

  return (
    <ul className={styles.actionList}>
      <li className={styles.actionItem}>
        <Button onClick={onOpenIconTable}>Icons & Tangrams</Button>
      </li>
      <li className={styles.actionItem}>
        <Button onClick={() => onOpenFeatureFlags()}>Feature flags</Button>
      </li>
      <li className={styles.actionItem}>
        <Button onClick={() => onOpenNotifications()}>Notifications</Button>
      </li>
      {status.linkText && status.linkHref ? (
        <li className={styles.actionItem}>
          <Button
            href={status.linkHref}
            target="_blank"
            className={styles.tooltipLink}
          >
            {status.linkText}
          </Button>
        </li>
      ) : null}
      {currentUser ? (
        <div className={styles.userInfoContainer}>
          <p>
            <span>🧑 User ID:</span> {currentUser.id}
          </p>
          <p>
            <span>🏢 Account ID:</span> {currentUser.account_id}
          </p>
          {currentUser.novu_subscriber_id && (
            <p>
              <span>🔔 Novu Subscriber ID:</span>{" "}
              {currentUser.novu_subscriber_id}
            </p>
          )}
          {subscriptionPlan && (
            <p>
              <span>📝 Plan:</span> {subscriptionPlan}
            </p>
          )}
        </div>
      ) : null}
    </ul>
  )
}
export default StatusTooltipContent
