import cc from "classcat"
import React from "react"
import { match } from "ts-pattern"

import styles from "./Alert.module.css"

import Button, { ButtonProps } from "../buttons/Button"
import { Danger, InfoCircle, Warning } from "../react-icons"

type AlertButtonProps = {
  label: string
  onClick: ButtonProps["onClick"]
}

type Props = {
  intent: "primary" | "warning" | "danger"
  title: JSX.Element | string
  description: JSX.Element | string
  primaryButton?: AlertButtonProps
  secondaryButton?: AlertButtonProps
}

const Alert = (props: Props) => {
  const { intent, title, description, primaryButton, secondaryButton } = props
  const icon = match(intent)
    .with("primary", () => (
      <InfoCircle fill={"var(--runn-blue)"} className={styles[intent]} />
    ))
    .with("warning", () => <Warning className={styles[intent]} />)
    .with("danger", () => <Danger className={styles[intent]} />)
    .exhaustive()

  return (
    <div className={cc([styles.container, styles[intent]])}>
      {icon}
      <div>
        <div>{title}</div>
        <div>{description}</div>
      </div>
      <div className={styles.buttonsContainer}>
        {secondaryButton && (
          <Button onClick={secondaryButton.onClick} intent={intent}>
            <span>{secondaryButton.label}</span>
          </Button>
        )}
        {primaryButton && (
          <Button
            onClick={primaryButton.onClick}
            intent={intent}
            outlined={false}
          >
            <span>{primaryButton.label}</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default Alert
