import { Dialog } from "@blueprintjs/core"
import React from "react"

import styles from "./NotificationsDialog.module.css"

import { sessionStore } from "~/store/hasura"

import Button from "~/common/buttons/Button"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AssetsDialog = (props: Props) => {
  const { isOpen, onClose } = props

  const onTimesheetNotCompleted = async () => {
    const hasuraAuthToken = await sessionStore.getAuthToken()
    const nodeServerEndpoint = await sessionStore.getNodeServerEndpoint()

    const response = await fetch(
      `${nodeServerEndpoint}/trigger-timesheet-not-completed-notification`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${hasuraAuthToken}`,
        },
      },
    )

    if (response.ok) {
      alert(
        "Success: If you have not completed timesheet last week, you should receive a notification shortly.",
      )
    } else {
      const text = await response.text()

      alert(`Error: ${text}`)
    }
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <h1>
          <span className={styles.bell}>🔔</span>
          Notifications
        </h1>
        <span>
          Click the button below to manually trigger notifications for the
          current Account. The Notifications flag must be enabled in the Feature
          Flags.
        </span>
        <div>
          <Button onClick={onTimesheetNotCompleted}>
            Timesheet not completed
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default AssetsDialog
