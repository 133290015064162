import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import { capitalize } from "lodash-es"
import React from "react"

import styles from "./AccountDetails.module.css"

import { AccountDropdownMenu_account$data } from "./__generated__/AccountDropdownMenu_account.graphql"

import { buildImageUrl } from "~/helpers/image"
import { formatName, formatNameAsInitials } from "~/helpers/person"

import Avatar from "~/common/Avatar"

type Props = AccountDropdownMenu_account$data

const AccountDetails = (props: Props) => {
  const { account, current_user } = props

  return (
    <div className={styles.container} data-test={"menu-button"}>
      <Avatar
        email={current_user.email}
        initials={formatNameAsInitials(
          current_user.first_name,
          current_user.last_name,
        )}
        avatar={buildImageUrl(current_user.image_key)}
        size={35}
        className={styles.avatar}
      />
      <div className={styles.details}>
        <div className={styles.account}>
          {account.name}
          <div
            className={cc([
              styles.type,
              {
                [styles.live]: account.account_type === "live",
                [styles.test]: account.account_type === "test",
              },
            ])}
          >
            {capitalize(account.account_type)}
          </div>
        </div>
        <div className={styles.user}>
          {formatName(current_user.first_name, current_user.last_name)}
        </div>
      </div>
      <Icon icon="chevron-down" className={styles.chevron} />
    </div>
  )
}

export default AccountDetails
