/**
 * @generated SignedSource<<fc415ef125d9c7c09636c506a39ae24a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountDropdownMenuContainerQuery$variables = {
  accountId: number;
};
export type AccountDropdownMenuContainerQuery$data = {
  readonly billing: {
    readonly " $fragmentSpreads": FragmentRefs<"EntitlementsProvider_billing">;
  } | null | undefined;
  readonly current_user: {
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AccountDropdownMenu_account" | "ServerFlagsProvider_gql">;
};
export type AccountDropdownMenuContainerQuery = {
  response: AccountDropdownMenuContainerQuery$data;
  variables: AccountDropdownMenuContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "syncSubscription",
    "value": false
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDropdownMenuContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntitlementsProvider_billing"
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "accountId",
            "variableName": "accountId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AccountDropdownMenu_account"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ServerFlagsProvider_gql"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountDropdownMenuContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInTrial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemPriceId",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscriptionEntitlement",
            "kind": "LinkedField",
            "name": "entitlements",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image_key",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "account",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "accountId"
          }
        ],
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "accounts_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timesheets_enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "alternative_account",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "default_full_time_minutes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "user_accounts",
        "kind": "LinkedField",
        "name": "user_accounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alternative_account_id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features_accounts",
        "kind": "LinkedField",
        "name": "features_accounts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feature_id",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "110a9c21548c441842e486de8f113c69",
    "id": null,
    "metadata": {},
    "name": "AccountDropdownMenuContainerQuery",
    "operationKind": "query",
    "text": "query AccountDropdownMenuContainerQuery(\n  $accountId: Int!\n) {\n  billing(syncSubscription: false) {\n    ...EntitlementsProvider_billing\n  }\n  current_user {\n    ...PermissionsProvider_user\n  }\n  ...AccountDropdownMenu_account_shlXP\n  ...ServerFlagsProvider_gql\n}\n\nfragment AccountDropdownMenu_account_shlXP on query_root {\n  account: accounts_by_pk(id: $accountId) {\n    id\n    name\n    account_type\n    timesheets_enabled\n    alternative_account {\n      account_type\n    }\n    currency\n    default_full_time_minutes\n  }\n  current_user {\n    id\n    first_name\n    last_name\n    email\n    image_key\n    ...PermissionsProvider_user\n  }\n  user_accounts {\n    id\n    account {\n      id\n      name\n      account_type\n      alternative_account_id\n    }\n  }\n  ...ServerFlagsProvider_gql\n}\n\nfragment EntitlementsProvider_billing on Billing {\n  subscription {\n    id\n    isInTrial\n    plan {\n      itemPriceId\n      id\n    }\n  }\n  entitlements {\n    featureId\n    featureType\n    value\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n}\n\nfragment ServerFlagsProvider_gql on query_root {\n  features {\n    id\n    enabled\n    description\n    name\n  }\n  features_accounts {\n    id\n    feature_id\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "95a32af89027021cd51daab2bedc6247";

export default node;
