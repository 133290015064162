import { sanitizeUrl } from "@braintree/sanitize-url"

import { EditType } from "~/PeopleList/PeopleEdit/types"

export const root = document.location.origin

const removeEmptyKeys = <T extends Record<string, string | null | undefined>>(
  record: T,
) => {
  return Object.fromEntries(
    Object.entries(record).filter((entry) => {
      return entry[1] !== null && entry[1] !== undefined
    }),
  ) as Record<string, string>
}

// Sanitize user input to avoid open redirects. Only allow relative paths, not absolute hosts.
// It's important to filter out special characters that might trigger inline Javascript execution, such as ":" for a "javascript:" prefix.
// See https://cheatsheetseries.owasp.org/cheatsheets/XSS_Filter_Evasion_Cheat_Sheet.html#url-string-evasion
export const sanitizePathname = (url: string): string => {
  // Base is needed for relative paths. We're not using it, just extracting the pathname.
  return new URL(sanitizeUrl(url), "https://app.runn.io").pathname.replace(
    /[^a-z0-9\/_-]*/gi,
    "",
  )
}

export const peoplePlannerUrl = (options: {
  personId?: number
  personRoleId?: number
  personTeamId?: number
  personWildSearch?: string
  calendarStartDate?: Date
}) => {
  const searchParams = new URLSearchParams(
    removeEmptyKeys({
      activePage: "people",
      PersonWildSearch: options.personWildSearch
        ? options.personWildSearch
        : undefined,
      Person: options.personId ? String(options.personId) : undefined,
      PersonTeam: options.personTeamId
        ? String(options.personTeamId)
        : undefined,
      PersonRole: options.personRoleId
        ? String(options.personRoleId)
        : undefined,
      CalendarStartDate: options.calendarStartDate
        ? String(options.calendarStartDate)
        : undefined,
    }),
  )
  return `${root}/?` + searchParams.toString()
}

export const projectPlannerUrl = (options: {
  projectId?: number
  showUnconfirmed: boolean
}) => {
  const searchParams = new URLSearchParams(
    removeEmptyKeys({
      activePage: "projects",
      unconfirmed: options.showUnconfirmed ? "true" : "false",
      Project: options.projectId ? String(options.projectId) : undefined,
    }),
  )
  return `${root}/?` + searchParams.toString()
}

export const templatePlannerUrl = (options: { templateId?: string }) => {
  const searchParams = new URLSearchParams(
    removeEmptyKeys({
      activePage: "projects",
      Template: options.templateId ? String(options.templateId) : undefined,
    }),
  )
  return `${root}/?` + searchParams.toString()
}

export const peopleListUrl = (search?: string) =>
  `${root}/people${search ? `?search=${search}` : ""}`

export const projectsListUrl = (search?: string) =>
  `${root}/projects${search ? `?search=${search}` : ""}`

export const bulkEditPeopleUrl = (
  options: { search?: string; type?: EditType } = {},
) => {
  const searchParams = new URLSearchParams(removeEmptyKeys(options))
  return `${root}/people/?${searchParams.toString()}#edit`
}

export const viewPersonUrl = (personHashId: string, view_timesheet_at?: Date) =>
  `${root}/people/${personHashId}${view_timesheet_at ? `?view_timesheet_at=${view_timesheet_at}` : ""}`

export const editPersonUrl = (personHashId: string) =>
  `${root}/people/${personHashId}/edit`

export const contractUrl = (personHashId: string, contractHashId: string) =>
  `${root}/people/${personHashId}/contracts/${contractHashId}`

export const editContractUrl = (personHashId: string, contractHashId: string) =>
  `${root}/people/${personHashId}/contracts/${contractHashId}/edit`

export const newContractUrl = (personHashId: string) =>
  `${root}/people/${personHashId}/contracts/new`

export const editPersonImageUrl = (personHashId: string) =>
  `${root}/people/${personHashId}/image`

export const editClientImageUrl = (personHashId: string) =>
  `${root}/clients/${personHashId}/image`

export const viewClientUrl = (clientHashId: string) =>
  `${root}/clients/${clientHashId}`

export const clientListUrl = () => `${root}/clients`

export const newProjectUrl = (clientHashId: string) =>
  `${root}/clients/${clientHashId}/projects/new`

export const viewProjectUrl = (projectHashId: string) =>
  `${root}/projects/${projectHashId}`

export const editProjectUrl = (projectHashId: string) =>
  `${root}/projects/${projectHashId}/edit`

export const viewTemplateUrl = (templateHashId: string) =>
  `${root}/project_templates/${templateHashId}`

export const viewRateCard = (rateCardHashId: string) =>
  `${root}/rate_cards/${rateCardHashId}`

export const newRateCardUrl = () => `${root}/rate_cards#new`

export const rateCardListUrl = () => `${root}/rate_cards`

export const editRateCardUrl = (rateCardHashId: string) =>
  `${root}/rate_cards/${rateCardHashId}/edit`

export const viewTeamUrl = (teamHashId: string) => `${root}/teams/${teamHashId}`

export const teamListUrl = () => `${root}/teams`

export const viewRoleUrl = (roleHashId: string) => `${root}/roles/${roleHashId}`

export const editRoleUrl = (roleHashId: string) =>
  `${root}/roles/${roleHashId}/edit`

export const archiveRoleUrl = (roleHashId: string) =>
  `${root}/roles/${roleHashId}/toggle_archive_status`

export const roleListUrl = () => `${root}/roles`

export const inviteUserUrl = () => `${root}/invitations/new`

export const editUserUrl = (userHashId: string) =>
  `${root}/users/${userHashId}/edit`

export const viewUserUrl = (userHashId: string) => `${root}/users/${userHashId}`

export const userListUrl = () => `${root}/users`

export const projectTemplateListUrl = () => `${root}/project_templates`

export const userSignOut = () => `${root}/users/sign_out`

export const viewInvitationUrl = (invitationHashId: string) =>
  `${root}/invitations/${invitationHashId}`

export const toggleUserTestAccount = (hashid: string) =>
  `${root}/account/toggle_user_test_account?user_id=${hashid}`

export const toggleTestAccount = () => `${root}/account/toggle_test_account`

export const switchAccount = () => `${root}/account/switch_account`

export const accountAPIUrl = () => `${root}/account/api`

export const createAccount = () => `${root}/account/create_account`

export const adminAccounts = () => `${root}/admin/accounts`

export const accountBilling = ({
  openSubscription,
}: {
  openSubscription?: boolean
}) =>
  `${root}/account/billing${openSubscription ? "?openSubscription=true" : ""}`

export const superAdminSwitchAccount = () =>
  `${root}/account/super_admin_switch`

export const superAdminLogout = () => `${root}/account/super_admin_logout`

export const copyDataFromLive = () => `${root}/account/copy_data_from_live`

export const importUrl = () => `${root}/import`

export const importsV2Url = () => `${root}/import_v2`

export const integrationsUrl = () => `${root}/integrations`

export const developerUrl = () => `${root}/developer`

export const developerV1Url = () => `https://developer.runn.io`

export const accountDeletedUrl = () => `${root}/bye/account_deleted`

export const selfDeletedUrl = () => `${root}/bye/user_deleted`

export const timesheetUrl = () => `${root}/timesheets`
export const resetAccount = () => `${root}/account/reset_account`
export const autofillAllTimesheetsWithZero = () =>
  `${root}/account/autofill_all_with_zero`

export const seedAccountWithDemoData = () =>
  `${root}/account/seed_account_with_demo_data`

export const syncAll = () => `${root}/account/sync_all`

export const viewHolidaysGroupUrl = (holidaysGroupHashId: string) =>
  `${root}/holidays/${holidaysGroupHashId}`

export const holidaysGroupListUrl = () => `${root}/holidays`

export const tagsListUrl = () => `${root}/tags`

export const skillsUrl = () => `${root}/skills`

export const hrIntegrationUrl = () => `${root}/integrations/hr`

export const projectIntegrationUrl = () => `${root}/integrations/project`

export const customFieldListUrl = () => `${root}/custom_fields`

export const workstreamListUrl = () => `${root}/workstreams`

export const exportUrl = () => `${root}/export`

export const viewsUrl = () => `${root}/views`

export default {
  root,
  peoplePlannerUrl,
  peopleListUrl,
  projectsListUrl,
  viewPersonUrl,
  editPersonUrl,
  editPersonImageUrl,
  editContractUrl,
  newContractUrl,
  editClientImageUrl,
  clientListUrl,
  viewClientUrl,
  newProjectUrl,
  viewRateCard,
  viewProjectUrl,
  editProjectUrl,
  viewTemplateUrl,
  projectPlannerUrl,
  newRateCardUrl,
  rateCardListUrl,
  editRateCardUrl,
  viewTeamUrl,
  teamListUrl,
  viewRoleUrl,
  editRoleUrl,
  archiveRoleUrl,
  roleListUrl,
  inviteUserUrl,
  editUserUrl,
  viewUserUrl,
  userListUrl,
  viewInvitationUrl,
  toggleUserTestAccount,
  toggleTestAccount,
  switchAccount,
  importUrl,
  integrationsUrl,
  developerUrl,
  accountDeletedUrl,
  selfDeletedUrl,
  timesheetUrl,
  resetAccount,
  seedAccountWithDemoData,
  syncAll,
  viewHolidaysGroupUrl,
  holidaysGroupListUrl,
  accountAPIUrl,
  tagsListUrl,
  skillsUrl,
  hrIntegrationUrl,
  bulkEditPeopleUrl,
  customFieldListUrl,
  accountBilling,
  viewsUrl,
}
